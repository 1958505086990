import React from "react"
import { css } from "styled-components"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const wrapperStyles = css`
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;

  h1 {
    text-align: center;
    font-size: 1.5em;
    margin-top: 50px;
    font-weight: 600;
    color: rgba(89, 89, 89, 0.7);
  }

  a {
    color: rgba(225, 187, 195, 1);
  }
`

const Cancelled = () => {
  return (
    <Layout>
      <SEO title="Payment cancelled" />

      <section css={wrapperStyles}>
        <h1>
          Oops - something went wrong!
          <br />
          Please <Link to="/registry">go back</Link> and try again.
        </h1>
      </section>
    </Layout>
  )
}

export default Cancelled
